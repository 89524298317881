export const Testimonials = (props) => {
  return (
    <div id='testimonials'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Información de algunos proyectos</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-12'>
                  <div className='testimonial'>                                   
                    <div className='testimonial-content'>
                      <p>{d.name}</p>
                      <div className='testimonial-meta'> 
                      {d.text}                                         
                    </div>
                  </div>
                 <div className='testimonial-image'>                   
                    <img src={d.img} alt='' />   
                 </div>
                 <div className='testimonial-autor'>
                 <a href={d.url} class="btn btn-primary">Ver proyecto</a> 
                 </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}


import * as Icon from 'react-bootstrap-icons';
export const Cards = (props) => {
return (
    <div id='sistemas' className='text-center'>
    <div className='container'>
      <div className='col-md-10 col-md-offset-1 section-title'>
        <br/>
        <h2>Sistemas que tenemos para la venta</h2>
      </div>
      <div className='row'>
        {props.data
          ? props.data.map((d, i) => (
              <div key={`${d.Titulo}-${i}`} className='col-xs-6 col-md-3'>
                {' '}
                <i className={d.icon}></i>
                <h3>{d.Titulo}</h3>
                <p>{d.Descripcion}</p>
                {d.Caracteristicas.map(c =>                      
               <p class="descripcion">{c.descripcion}</p>       
                )}   
                <a href={`https://api.whatsapp.com/send?phone=50256306723&text=Hola!%20Necesito informacion%20sobre el sistema ${d.Titulo}`} class="btn btn-success">Cotizar <Icon.Whatsapp/></a>              
              </div>
            ))
          : 'Loading...'}
         
      </div>
      
    </div>
  </div>

)}